import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function Info({
  type,
  children,
}: PropsWithChildren<{ type: "success" | "info" | "error" }>) {
  return (
    <Alert status={type}>
      <AlertIcon />
      <AlertDescription>{children}</AlertDescription>
    </Alert>
  );
}
