import { atomFamily } from "recoil";
import { localStorageEffect } from "../../shared/state/local-storage.effect";

export const selectedPickupDateState = atomFamily<Date | undefined, string>({
  key: "camping.rent.selectedPickupDate",
  default: new Date(),
  effects: [
    localStorageEffect<Date | undefined>({
      serialize: (value) => value?.toISOString() ?? "",
      deserialize: (json) => (json ? new Date(json) : undefined),
    }),
  ],
});
