import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IconUpload } from "./icons";

export const FileUpload = ({
  label,
  name,
  error,
  onChange,
}: {
  label: string;
  name: string;
  error?: string;
  onChange: (file: File) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      onChange(file);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IconUpload />
        </InputLeftElement>
        <input
          type="file"
          accept="text/csv"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <Input
          placeholder="Datei auswählen..."
          onClick={() => {
            inputRef.current?.click();
          }}
          id={name}
          name={name}
          value={fileName}
          readOnly
          variant="filled"
        />
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
