import { Container } from "@chakra-ui/react";
import { PropsWithChildren, useState } from "react";
import { AuthenticationContext } from "./authentication/authentication.context";
import { tokenStorage } from "./shared/api";

export function AppRoot({ children }: PropsWithChildren) {
  const [token, _setToken] = useState(tokenStorage.get());

  const setToken = (token: string | null) => {
    _setToken(token);
    tokenStorage.set(token);
  };

  return (
    <AuthenticationContext.Provider value={{ token, setToken }}>
      <Container
        maxW="1280px"
        margin="0 auto"
        padding="2rem"
        textAlign="center"
      >
        {children}
      </Container>
    </AuthenticationContext.Provider>
  );
}
