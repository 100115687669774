import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TenantResponse } from "use-smart-locks-shared";
import useAppLogo from "../assets/use-app-icon.png";
import { useTenantUpdate, useTenantsQuery } from "../shared/api";
import { ApiRequest } from "../shared/components/api-request.component";
import { AddEmailForm } from "./add-email-form.component";
import { EmailsCsvImportForm } from "./emails-csv-import.component";

export function UsersPage() {
  const tenantId = "backnang";
  const query = useTenantsQuery(tenantId);
  const { data } = query;
  const [tenant, setTenant] = useState<TenantResponse | null>(null);
  const updateTenant = useTenantUpdate();
  const [emailToDelete, setEmailToDelete] = useState<string | null>(null);
  const toast = useToast();

  const save = (newTenant: TenantResponse) =>
    updateTenant.mutateAsync({
      tenantId: newTenant.id,
      body: newTenant,
    });

  useEffect(() => {
    if (data) {
      data.data.data.allowedEmails.sort();
      setTenant(data.data.data);
    }
  }, [data]);

  const deleteEmail = async (email: string) => {
    if (!tenant) return;
    const newTenant = {
      ...tenant,
      allowedEmails: tenant.allowedEmails.filter((e) => e !== email),
    };
    setTenant(newTenant);
    await save(newTenant);
    setEmailToDelete(null);

    toast({
      title: "E-Mail gelöscht",
      status: "success",
      duration: 5000,
    });
  };

  const addEmails = async (emails: string[]) => {
    if (!tenant) return;

    const allowedEmails = emails
      .filter((email) => !tenant.allowedEmails.includes(email))
      .concat(tenant.allowedEmails)
      .sort();

    const newTenant = {
      ...tenant,
      allowedEmails,
    };

    setTenant(newTenant);
    await save(newTenant);

    toast({
      title: "E-Mail hinzugefügt",
      status: "success",
      duration: 5000,
    });
  };

  const overwriteEmails = async (emails: string[]) => {
    if (!tenant) return;

    const allowedEmails = emails.sort();

    const newTenant = {
      ...tenant,
      allowedEmails,
    };

    setTenant(newTenant);
    await save(newTenant);
    toast({
      title: "E-Mails importiert",
      status: "success",
      duration: 5000,
    });
  };

  return (
    <Flex direction="column" gap="64px" w="800px" maxW="100%" margin="auto">
      <Flex gap="32px" direction="column" align="center">
        <div>
          <img src={useAppLogo} className="shared-logo" alt="Use logo" />
        </div>
        <Heading as="h1" size="lg" textAlign="center">
          Bike-Sharing Nutzer der Stadt Backnang
        </Heading>
      </Flex>

      {emailToDelete && (
        <DeleteUserModal
          onConfirm={() => deleteEmail(emailToDelete)}
          onCancel={() => setEmailToDelete(null)}
        />
      )}

      <ApiRequest result={query}>
        <Tabs isFitted>
          <TabList>
            <Tab>Nutzer</Tab>
            <Tab>Nutzer hinzufügen</Tab>
            <Tab>Nutzer importieren</Tab>
          </TabList>
          <TabPanels paddingTop="16px">
            <TabPanel>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>E-Mail</Th>
                      <Th>Löschen</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tenant?.allowedEmails.map((email, index) => (
                      <Tr key={index}>
                        <Td>{email}</Td>
                        <Td>
                          <Button
                            type="button"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setEmailToDelete(email);
                            }}
                          >
                            Löschen
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel>
              <Flex
                direction="column"
                gap="32px"
                width="360px"
                maxW="100%"
                margin="auto"
              >
                <AddEmailForm
                  existingEmails={tenant?.allowedEmails ?? []}
                  addEmail={(email) => void addEmails([email])}
                  isLoading={updateTenant.isPending}
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                direction="column"
                gap="32px"
                width="360px"
                maxW="100%"
                margin="auto"
              >
                <EmailsCsvImportForm
                  importEmails={(emails) => void overwriteEmails(emails)}
                  isLoading={updateTenant.isPending}
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ApiRequest>
    </Flex>
  );
}

function DeleteUserModal({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={true} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nutzer löschen</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Möchten Sie den Nutzer wirklich löschen?</ModalBody>

        <ModalFooter gap="8px">
          <Button variant="ghost" onClick={onCancel}>
            Abbrechen
          </Button>
          <Button onClick={() => void handleConfirm()} isLoading={isLoading}>
            Nutzer löschen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
