import * as Yup from "yup";

export const apiErrorSchema = Yup.object({
  error: Yup.object({
    message: Yup.string().required(),
    statusCode: Yup.number().required(),
  }).required(),
});

export type ApiError = Yup.InferType<typeof apiErrorSchema>;
