import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useLocation } from "react-router-dom";
import { emailAuthenticationRequestSchema } from "use-smart-locks-shared";
import { useStationAuthenticate } from "../../shared/api";
import { ApiError } from "../../shared/components/api-error.component";
import { Info } from "../../shared/components/info.component";

export function StationLoginComponent({ stationId }: { stationId: string }) {
  const authenticate = useStationAuthenticate();

  const location = useLocation();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => {
          authenticate.mutate({
            stationId,
            body: { email: values.email },
          });
        }}
        validationSchema={emailAuthenticationRequestSchema}
      >
        {({ handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit} className="shared-max-width-100">
            <Flex direction="column" gap="32px" w="320px" maxW="100%">
              <FormControl isInvalid={!!errors.email && touched.email}>
                <FormLabel htmlFor="email">E-Mail-Adresse</FormLabel>
                <Field
                  as={Input}
                  id="email"
                  name="email"
                  type="email"
                  variant="filled"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <Button type="submit" isLoading={authenticate.isPending}>
                Station öffnen
              </Button>
              {authenticate.data?.data.message && (
                <Info type="info">{authenticate.data.data.message}</Info>
              )}
              <ApiError
                result={authenticate}
                redirectPathIfUnauthorized={location.pathname}
              />
            </Flex>
          </form>
        )}
      </Formik>
    </>
  );
}
