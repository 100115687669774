import { useCallback } from "react";
import { useResetRecoilState } from "recoil";
import { selectedDurationState } from "../state/selected-duration.state";
import { selectedPickupDateState } from "../state/selected-pickup-date.state";
import { selectedUnitIdsState } from "../state/selected-unit-ids.state";
import { useCampingStationSlug } from "./use-camping-station-slug";

export const useResetCampingState = (): (() => void) => {
  const slug = useCampingStationSlug();

  const resetSelectedPickupDate = useResetRecoilState(
    selectedPickupDateState(slug),
  );
  const resetSelectedDuration = useResetRecoilState(
    selectedDurationState(slug),
  );
  const resetSelectedUnitIds = useResetRecoilState(selectedUnitIdsState(slug));

  const resetCampingState = useCallback(() => {
    resetSelectedPickupDate();
    resetSelectedDuration();
    resetSelectedUnitIds();
  }, [resetSelectedDuration, resetSelectedPickupDate, resetSelectedUnitIds]);

  return resetCampingState;
};
