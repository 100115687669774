import { CheckIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import {
  Avatar,
  Blockquote,
  Box,
  Button,
  Callout,
  Card,
  Flex,
  Heading,
  Text,
} from "@radix-ui/themes";
import { isToday, isTomorrow } from "date-fns";
import { Link as NavLink } from "react-router-dom";
import { formatDate, formatTime } from "use-smart-locks-shared";
import { useCampingBooking } from "../hooks/use-camping-booking";

export const CampingBookingSuccessPage: React.FC = () => {
  const booking = useCampingBooking();

  const multipleItems = booking.units.length > 1;

  const hasDirectLink = isToday(booking.starts) || isTomorrow(booking.starts);

  return (
    <Flex direction="column" gap="4">
      <Flex justify="center">
        <Flex
          style={{
            backgroundColor: "var(--green-10)",
            borderRadius: "50%",
            padding: "5px",
            width: "70px",
            height: "70px",
            textAlign: "center",
          }}
        >
          <CheckIcon color="white" width="100%" height="100%" />
        </Flex>
      </Flex>
      <Heading align="center" as="h2" size="5">
        Buchung erfolgreich!
      </Heading>
      <Text>
        {multipleItems ? "Ihre Räder wurden" : "Ihr Rad wurde"} für Sie
        reserviert und {multipleItems ? "warten" : "wartet"} nun auf das
        gemeinsame Abenteuer mit Ihnen.
      </Text>

      <Callout.Root>
        <Callout.Icon>
          <InfoCircledIcon />
        </Callout.Icon>
        <Callout.Text>
          {hasDirectLink
            ? `Sie können ${multipleItems ? "Ihre Räder" : "Ihr Rad"} über folgenden Link direkt in der Station abholen. Wir haben Ihnen den Link auch per E-Mail geschickt.`
            : `Am Tag der Miete können Sie ${multipleItems ? "Ihre Räder" : "Ihr Rad"} direkt in der Station abholen. Folgen Sie dazu einfach den Anweisungen in der Bestätigungs-E-Mail.`}
        </Callout.Text>
      </Callout.Root>

      {hasDirectLink && (
        <Button asChild>
          <NavLink to="..">{`Holen Sie ${multipleItems ? "Ihre Fahrräder" : "Ihr Fahrrad"} jetzt ab`}</NavLink>
        </Button>
      )}

      <Text>Hier eine Übersicht über die Buchungsdaten:</Text>
      <Blockquote>
        am {formatDate(booking.starts)}, von {formatTime(booking.starts)} bis{" "}
        {formatTime(booking.ends)}
      </Blockquote>

      <Flex direction="column" gap="2">
        {booking.units.map((unit) => (
          <Card key={unit.id} size="1">
            <Flex gap="3" align="center">
              <Avatar
                className="unit"
                size="3"
                src={unit.imageUrl}
                radius="large"
                fallback={unit.name.charAt(0)}
              />
              <Box flexGrow="1">
                <Text as="div" size="2" weight="bold">
                  {unit.name}
                </Text>
                <Text as="div" size="2" color="gray">
                  {unit.description}
                </Text>
              </Box>
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};
