import { useParams } from "react-router-dom";

export const useCampingBookingSlug = (): string => {
  const { bookingSlug } = useParams();

  if (!bookingSlug) {
    throw new Error("bookingSlug route parameter is required");
  }

  return bookingSlug;
};
