import { UseToastOptions, createStandaloneToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  EmailAuthenticationRequest,
  EmailAuthenticationResponse,
  StationsOpenRequest,
  TenantResponse,
  TenantUpdateRequest,
  TokenAuthenticationRequest,
  apiErrorSchema,
  isValid,
} from "use-smart-locks-shared";
import { env } from "../../env";
import { queryClient } from "./query-client";

/** If possible, prefer using AuthenticationContext. */
export const tokenStorage = {
  get: () => localStorage.getItem("token"),
  set: (token: string | null) =>
    token
      ? localStorage.setItem("token", token)
      : localStorage.removeItem("token"),
};

const apiUrl = env.lambdaApiUrl;
if (!apiUrl) {
  throw new Error("API URL is not defined");
}

export function parseApiError(error: AxiosError | Error) {
  if (error instanceof AxiosError && error.response?.data) {
    const data = error.response.data as unknown;
    if (isValid(data, apiErrorSchema)) {
      return data.error;
    }
  }
  return { message: error.message, statusCode: -1 };
}

export const unauthorizedToast = {
  title: "Nicht authentifiziert",
  description: "Bitte melden Sie sich erneut an.",
  status: "error",
  duration: 9000,
  id: "unauthorized",
} satisfies UseToastOptions;

const handleApiError = (error: Error) => {
  const parsed = parseApiError(error);
  const isUnauthorized = parsed.statusCode === 401;

  const { toast } = createStandaloneToast();
  toast(
    isUnauthorized
      ? unauthorizedToast
      : {
          title: "Fehler",
          description: parsed.message,
          status: "error",
          duration: 5000,
        },
  );
};

export function useStationAuthenticate() {
  return useMutation({
    mutationFn: (payload: {
      stationId: string;
      body: EmailAuthenticationRequest;
    }) =>
      axios.post<EmailAuthenticationResponse>(
        `${apiUrl}/stations/${payload.stationId}/authenticate`,
        payload.body,
      ),
  });
}

export function useStationOpen() {
  return useMutation({
    mutationFn: (payload: { stationId: string; body: StationsOpenRequest }) =>
      axios.post(`${apiUrl}/stations/${payload.stationId}/open`, payload.body),
  });
}

export function useAuthenticationAuthenticateWithEmail() {
  return useMutation({
    mutationFn: (payload: { body: EmailAuthenticationRequest }) =>
      axios.post<EmailAuthenticationResponse>(
        `${apiUrl}/authentication/authenticate-with-email`,
        payload.body,
      ),
  });
}

export function useAuthenticationAuthenticateWithToken() {
  return useMutation({
    mutationFn: (payload: { body: TokenAuthenticationRequest }) =>
      axios.post(
        `${apiUrl}/authentication/authenticate-with-token`,
        payload.body,
      ),
  });
}

export function useTenantsQuery(tenantId: string) {
  return useQuery({
    queryKey: ["tenants", tenantId],
    queryFn: ({ queryKey }) =>
      axios.get<{ data: TenantResponse }>(`${apiUrl}/tenants/${queryKey[1]}`, {
        headers: authorizationHeaderIfToken(),
      }),
  });
}

export function useTenantUpdate() {
  return useMutation({
    mutationFn: (payload: { tenantId: string; body: TenantUpdateRequest }) =>
      axios.put(`${apiUrl}/tenants/${payload.tenantId}`, payload.body, {
        headers: authorizationHeaderIfToken(),
      }),
    onError: handleApiError,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ["tenants"] });
    },
  });
}

function authorizationHeaderIfToken() {
  const token = tokenStorage.get();
  return token ? { Authorization: `Bearer ${token}` } : {};
}
