import {
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isBefore,
} from "date-fns";

export function formatTimeUntil(
  date: Date | string,
  thresholdMinutes: number,
): string {
  const targetDate = addMinutes(new Date(date), -thresholdMinutes);
  const now = new Date();

  const secondsUntil = differenceInSeconds(targetDate, now);
  if (secondsUntil <= 0) return "jetzt";
  if (secondsUntil < 60)
    return `in ${secondsUntil} Sekunde${secondsUntil > 1 ? "n" : ""}`;

  const minutesUntil = differenceInMinutes(targetDate, now);
  if (minutesUntil < 60) return `in ${minutesUntil + 1} Minuten`;

  const hoursUntil = differenceInHours(targetDate, now);
  if (hoursUntil < 24)
    return `in ${hoursUntil} Stunde${hoursUntil > 1 ? "n" : ""}`;

  const daysUntil = differenceInDays(targetDate, now);
  return `in ${daysUntil} Tag${daysUntil > 1 ? "en" : ""}`;
}

export function isInFuture(
  date: Date | string,
  thresholdMinutes: number,
): boolean {
  const targetDate = addMinutes(new Date(date), -thresholdMinutes);
  const now = new Date();

  return differenceInSeconds(targetDate, now) > 0;
}

export function isAtMostNHoursAfter(date: Date | string, n: number) {
  const currentDate = new Date();
  const twelveHoursAfterDate = addHours(new Date(date), n);

  return isBefore(currentDate, twelveHoursAfterDate);
}
