import { createContext } from "react";

type AuthenticationContextType = {
  token: string | null;
  setToken: (token: string | null) => void;
};

const defaultContext: AuthenticationContextType = {
  token: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToken: () => {},
};

export const AuthenticationContext = createContext(defaultContext);
