import type React from "react";

type CampingBookingRootProps = {
  children: React.ReactNode;
};

export default function CampingBookingRoot({
  children,
}: CampingBookingRootProps) {
  return children;
}
