import { Button } from "@radix-ui/themes";
import React from "react";
import { env } from "../../env";

export const ContactUsButton: React.FC = () => (
  <Button asChild>
    {/* eslint-disable-next-line react/jsx-no-target-blank */}
    <a href={env.contactPage} target="_blank">
      Jetzt kontaktieren
    </a>
  </Button>
);
