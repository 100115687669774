import { Navigate } from "react-router-dom";
import { useCampingBooking } from "../camping/hooks/use-camping-booking";
import { routes } from "../routes";

/**
 * Simple forward from /bookings/:slug to /stations/:stationSlug/bookings/:bookingSlug.
 *
 * By redirecting to a station-subpage, we can keep all station-related content (e.g. header) in one place.
 */
export const BookignsForwardToStationPage: React.FC = () => {
  const booking = useCampingBooking();

  const stationSlug = booking.stationSlug;

  const stationBookingRoute = routes.camping.booking.unlock(
    stationSlug,
    booking.slug,
  );

  return <Navigate to={stationBookingRoute} replace />;
};
