import { PostHogConfig } from "posthog-js";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { env } from "../../env";

const enabled = process.env.NODE_ENV === "production"; // not using vite mode because we want to use sentry in staging etc. as well

export const posthogConfig: {
  options: Partial<PostHogConfig>;
  apiKey: string | undefined;
} = {
  options: {
    api_host: env.posthogHost,
    persistence: "memory", // cookieless mode, see https://posthog.com/tutorials/cookieless-tracking
    autocapture: true,
    capture_pageview: false,
    debug: import.meta.env.DEV,
  },
  apiKey: enabled ? env.posthogKey : undefined,
};

export const useTrackPageView = (): void => {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    const url = window.origin + location.pathname + location.search;

    posthog.capture("$pageview", {
      $current_url: url,
    });
  }, [posthog, location]);
};
