export const bookingDurations = ["1", "2", "3", "4", "5", "day"] as const;

export type BookingDuration = (typeof bookingDurations)[number];

export const bookingDurationLabels: Record<BookingDuration, string> = {
  "1": "1 Stunde",
  "2": "2 Stunden",
  "3": "3 Stunden",
  "4": "4 Stunden",
  "5": "5 Stunden",
  day: "den gesamten restlichen Tag",
};
