import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Callout, Flex } from "@radix-ui/themes";

export const NotFound: React.FC = () => {
  return (
    <Flex align="center" justify="center" p="5">
      <Callout.Root color="red">
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>
          <p>Diese Seite existiert nicht.</p>
          <br />
          <p>
            Es sieht so aus, als hätten Sie einen Glitch in der Matrix gefunden!
          </p>
        </Callout.Text>
      </Callout.Root>
    </Flex>
  );
};
