import * as Yup from "yup";

export const emailFieldSchema = Yup.string()
  .email("Bitte geben Sie eine gültige E-Mail-Adresse ein.")
  .required("Bitte geben Sie Ihre E-Mail-Adresse ein.");

export const emailAuthenticationRequestSchema = Yup.object({
  email: emailFieldSchema,
});

export type EmailAuthenticationRequest = Yup.InferType<
  typeof emailAuthenticationRequestSchema
>;
