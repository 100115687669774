import { Flex, Spinner } from "@chakra-ui/react";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { PropsWithChildren } from "react";
import { ApiError } from "./api-error.component";

export function ApiRequest({
  result,
  children,
}: PropsWithChildren<{
  result: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UseMutationResult<AxiosResponse, Error, any> | UseQueryResult<AxiosResponse>;
}>) {
  const isLoading = result.isPending;
  return isLoading ? (
    <Flex justify="center">
      <Spinner />
    </Flex>
  ) : (
    <ApiError result={result}>{children}</ApiError>
  );
}
