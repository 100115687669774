import * as Yup from "yup";

export function isValid<T>(data: unknown, schema: Yup.Schema<T>): data is T {
  try {
    schema.validateSync(data);
    return true;
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return false;
    }
    throw e;
  }
}
