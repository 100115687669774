import {
  FetchQueryOptions,
  UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { CampingBooking } from "use-smart-locks-shared";
import { queryClient } from "../../shared/api";
import { getBookingBySlug } from "../../shared/api/nest-api";

const queryOptions = (
  bookingSlug: string,
): FetchQueryOptions<CampingBooking> => ({
  queryKey: ["camping-booking", bookingSlug],
  queryFn: () => getBookingBySlug(bookingSlug),
});

export const useCampingBookingQuery = (
  bookingSlug: string,
): UseSuspenseQueryResult<CampingBooking> =>
  useSuspenseQuery(queryOptions(bookingSlug));

export const prefetchCampingBookingQuery = (bookingId: string): Promise<void> =>
  queryClient.prefetchQuery(queryOptions(bookingId));
