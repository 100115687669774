import { Button, Flex } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { AuthenticationContext } from "../../authentication/authentication.context";
import { routes } from "../../routes";
import { useStationOpen } from "../../shared/api";
import { ApiError } from "../../shared/components/api-error.component";
import { IconLock, IconLockOpen } from "../../shared/components/icons";
import { Info } from "../../shared/components/info.component";

export function StationOpenComponent({
  stationId,
  token,
}: {
  stationId: string;
  token: string;
}) {
  const authenticationContext = useContext(AuthenticationContext);
  const openStation = useStationOpen();
  useEffect(() => {
    if (token !== authenticationContext.token) {
      authenticationContext.setToken(token);
    }
  }, [token, authenticationContext]);

  const open = () => {
    openStation.mutate({
      stationId,
      body: {
        token,
      },
    });
  };

  return (
    <Flex
      direction="column"
      gap="32px"
      w="320px"
      maxW="100%"
      alignItems="center"
    >
      {openStation.isSuccess ? (
        <>
          <IconLockOpen width={80} height={80} />
          <Info type="success">Die Station is geöffnet!</Info>
        </>
      ) : (
        <>
          <IconLock width={80} height={80} />
          <Info type="info">Die Station is geschlossen.</Info>
        </>
      )}
      <Button
        type="submit"
        onClick={open}
        isLoading={openStation.isPending}
        width={"100%"}
      >
        Station öffnen
      </Button>
      <ApiError
        result={openStation}
        redirectPathIfUnauthorized={routes.station(stationId)}
      />
    </Flex>
  );
}
