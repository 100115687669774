import { Select } from "@radix-ui/themes";
import { useCallback } from "react";
import { BookingDuration, bookingDurationLabels } from "use-smart-locks-shared";

export const DurationSelect: React.FC<{
  id?: string;
  value: BookingDuration | undefined;
  onChange: (duration: BookingDuration | undefined) => void;
  disabled?: boolean;
}> = ({ id, value, onChange, disabled }) => {
  const handleValueChange = useCallback(
    (valueString: string) => {
      onChange(valueString as BookingDuration | undefined);
    },
    [onChange],
  );

  const selectedOption = value ?? "";

  return (
    <Select.Root
      value={selectedOption}
      onValueChange={handleValueChange}
      disabled={disabled}
    >
      <Select.Trigger placeholder="Zeitspanne auswählen" id={id} />
      <Select.Content
        /** prevent click through: https://github.com/radix-ui/primitives/issues/1658#issuecomment-2108955928 */
        ref={(ref) =>
          ref?.addEventListener("touchend", (e) => e.preventDefault())
        }
      >
        <Select.Group>
          <Select.Label>Zeitspanne</Select.Label>
          {Object.entries(bookingDurationLabels).map(([key, value]) => (
            <Select.Item key={key} value={key}>
              {value}
            </Select.Item>
          ))}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
