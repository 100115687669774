import { CampingStation } from "use-smart-locks-shared";
import { useCampingStationQuery } from "../queries/use-camping-station-query";
import { useCampingStationSlug } from "./use-camping-station-slug";

export const useCampingStation = (): CampingStation => {
  const stationSlug = useCampingStationSlug();

  const { data, isError } = useCampingStationQuery(stationSlug);

  if (isError) {
    throw new Error("Loading camping station failed.");
  }

  return data;
};
