import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { emailFieldSchema } from "use-smart-locks-shared";
import * as Yup from "yup";

export function AddEmailForm({
  existingEmails,
  isLoading,
  addEmail,
}: {
  existingEmails: string[];
  isLoading: boolean;
  addEmail: (email: string) => void;
}) {
  const formValidationSchema = Yup.object({
    email: emailFieldSchema.test(
      "unique",
      "E-Mail-Adresse bereits vorhanden",
      (value) => (existingEmails.includes(value) ? false : true),
    ),
  });

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(values, actions) => {
        addEmail(values.email);
        actions.resetForm();
      }}
      validationSchema={formValidationSchema}
    >
      {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="32px">
            <FormControl isInvalid={!!errors.email && touched.email}>
              <FormLabel htmlFor="email">Neue E-Mail-Adresse</FormLabel>
              <Field
                as={Input}
                id="email"
                name="email"
                type="email"
                variant="filled"
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <Button type="submit" isLoading={isLoading}>
              Hinzufügen
            </Button>
          </Flex>
        </form>
      )}
    </Formik>
  );
}
