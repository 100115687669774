import { Container, Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren, Suspense } from "react";
import { FullSpinner } from "../shared/components/full-spinner";
import { NotFoundOrIntermittentError } from "../shared/components/not-found-or-intermittent-error";
import { CampingHeader } from "./components/camping-header.component";

export const CampingRoot: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container size="1" p="2">
      <ErrorBoundary fallback={<NotFoundOrIntermittentError />}>
        <Suspense fallback={<FullSpinner />}>
          <Flex gap="4" direction="column" align="center">
            <CampingHeader />

            <Suspense fallback={<FullSpinner />}>{children}</Suspense>
          </Flex>
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
};
