import { InfoCircledIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Button, Callout, Flex } from "@radix-ui/themes";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect } from "react";
import { routes } from "../../routes";
import { useCampingStationSlug } from "../hooks/use-camping-station-slug";
import { useResetCampingState } from "../hooks/use-reset-camping-state";

export const CampingRentErrorReset: React.FC = () => {
  const slug = useCampingStationSlug();

  const resetCampingState = useResetCampingState();

  const resetAndStartOverAgain = useCallback(() => {
    resetCampingState();

    window.location.replace(routes.camping.rent.index(slug));
  }, [slug, resetCampingState]);

  const posthog = usePostHog();
  useEffect(() => {
    posthog.capture("error", { boundary: "CampingRentErrorReset" });
  }, [posthog]);

  return (
    <Flex gap="4" direction="column">
      <Callout.Root color="red">
        <Callout.Icon>
          <InfoCircledIcon />
        </Callout.Icon>
        <Callout.Text>Leider ist ein Fehler aufgetreten.</Callout.Text>
      </Callout.Root>

      <Button onClick={resetAndStartOverAgain}>
        <ReloadIcon /> Klicken Sie hier um es erneut zu versuchen.
      </Button>
    </Flex>
  );
};
