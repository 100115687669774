import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren } from "react";
import { CampingRentErrorReset } from "./components/camping-rent-error-reset.component";

export const CampingRentRoot: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary fallback={<CampingRentErrorReset />}>
      {children}
    </ErrorBoundary>
  );
};
