import { Flex, Heading } from "@chakra-ui/react";
import useAppLogo from "./assets/use-app-icon.png";

function AppPage() {
  return (
    <Flex gap="112px" direction="column" align="center">
      <Flex gap="32px" direction="column" align="center">
        <div>
          <img src={useAppLogo} className="shared-logo" alt="Use logo" />
        </div>
        <Heading as="h1">use Group</Heading>
      </Flex>
    </Flex>
  );
}

export default AppPage;
