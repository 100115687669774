import { useToast } from "@chakra-ui/react";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthenticationContext } from "../../authentication/authentication.context";
import { parseApiError, unauthorizedToast } from "../api";
import { Info } from "./info.component";

export function ApiError({
  result,
  children,
  redirectPathIfUnauthorized = "/authentication/login",
}: PropsWithChildren<{
  result: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UseMutationResult<AxiosResponse, Error, any> | UseQueryResult<AxiosResponse>;
  redirectPathIfUnauthorized?: string;
}>) {
  const authenticationContext = useContext(AuthenticationContext);
  const toast = useToast();
  if (!result.isError) {
    return children;
  }
  const parsed = parseApiError(result.error);
  const isUnauthorized = parsed.statusCode === 401;

  if (isUnauthorized) {
    if (!toast.isActive(unauthorizedToast.id)) {
      toast(unauthorizedToast);
    }

    authenticationContext.setToken(null);
    return <Navigate to={redirectPathIfUnauthorized} />;
  }

  return <Info type="error">{parsed.message}</Info>;
}
