import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Callout, Flex } from "@radix-ui/themes";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export const NotFoundOrIntermittentError: React.FC = () => {
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture("error", { boundary: "NotFoundOrIntermittentError" });
  }, [posthog]);

  return (
    <Flex align="center" justify="center" p="5">
      <Callout.Root color="red">
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>
          <p>Ups, das hat leider nicht geklappt!</p>
          <br />
          <p>
            Wenn Sie sicher sind, dass Sie hier richtig sind, versuchen Sie es
            bitte in ein paar Minuten erneut.
          </p>
        </Callout.Text>
      </Callout.Root>
    </Flex>
  );
};
