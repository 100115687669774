import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { unlockLocksOfBooking } from "../../shared/api/nest-api";

type MutationFn = typeof unlockLocksOfBooking;

export function useUnlockLocksOfBookingMutation(
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<MutationFn>>,
      Error,
      Parameters<MutationFn>[0]
    >,
    "mutationFn"
  >,
) {
  return useMutation({ mutationFn: unlockLocksOfBooking, ...options });
}
