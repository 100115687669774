import { atomFamily } from "recoil";
import { BookingDuration } from "use-smart-locks-shared";
import { localStorageEffect } from "../../shared/state/local-storage.effect";

export const selectedDurationState = atomFamily<
  BookingDuration | undefined,
  string
>({
  key: "camping.rent.selectedDuration",
  default: undefined,
  effects: [localStorageEffect()],
});
