import { CampingBooking } from "use-smart-locks-shared";
import { useCampingBookingQuery } from "../queries/use-camping-booking-query";
import { useCampingBookingSlug } from "./use-camping-booking-slug";

export const useCampingBooking = (): CampingBooking => {
  const bookingSlug = useCampingBookingSlug();

  const { data } = useCampingBookingQuery(bookingSlug);

  return data;
};
