import { Container } from "@radix-ui/themes";
import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren, Suspense } from "react";
import { FullSpinner } from "../shared/components/full-spinner";
import { NotFoundOrIntermittentError } from "../shared/components/not-found-or-intermittent-error";

export const BookingsRoot: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container size="1" p="2">
      <ErrorBoundary fallback={<NotFoundOrIntermittentError />}>
        <Suspense fallback={<FullSpinner />}>{children}</Suspense>
      </ErrorBoundary>
    </Container>
  );
};
