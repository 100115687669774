import {
  HamburgerMenuIcon,
  OpenInNewWindowIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  DropdownMenu,
  Flex,
  Heading,
  IconButton,
  Link,
  Separator,
} from "@radix-ui/themes";
import { Link as RouterLink } from "react-router-dom";
import useAppLogo from "../../assets/use-app-icon.png";
import { env } from "../../env";
import { routes } from "../../routes";
import { useCampingStation } from "../hooks/use-camping-station";
import { useCampingStationSlug } from "../hooks/use-camping-station-slug";

export const CampingHeader: React.FC = () => {
  const campingStation = useCampingStation();

  const campingStationSlug = useCampingStationSlug();
  const campingStationHomeRoute = routes.camping.index(campingStationSlug);
  const campingStationRentRoute = routes.camping.rent.index(campingStationSlug);

  return (
    <Box width="100%">
      <Flex direction="row" gap="2" align="center">
        <RouterLink to={campingStationHomeRoute} style={{ flexShrink: "0" }}>
          <img src={useAppLogo} className="shared-logo-small" alt="Use logo" />
        </RouterLink>

        <Box flexGrow="1" minWidth="0">
          <RouterLink to={campingStationHomeRoute}>
            <Heading as="h1" truncate>
              {campingStation.name}
            </Heading>
          </RouterLink>
        </Box>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton color="gray" variant="outline">
              <HamburgerMenuIcon width="18" height="18" />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item asChild>
              <RouterLink to={campingStationRentRoute}>
                <Flex as="span" align="center" gap="1">
                  <PlusCircledIcon /> Neue Buchung
                </Flex>
              </RouterLink>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild>
              <Link href={env.contactPage} target="_blank">
                <Flex as="span" align="center" gap="1">
                  <OpenInNewWindowIcon /> Kontakt
                </Flex>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild>
              <Link
                href="https://www.usegroup.eu/impressum"
                target="_blank"
                style={{ verticalAlign: "middle" }}
              >
                <Flex as="span" align="center" gap="1">
                  <OpenInNewWindowIcon /> Impressum
                </Flex>
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild>
              <Link href="https://www.usegroup.eu/datenschutz" target="_blank">
                <Flex as="span" align="center" gap="1">
                  <OpenInNewWindowIcon /> Datenschutz
                </Flex>
              </Link>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <Box mt="2">
        <Separator size="4" />
      </Box>
    </Box>
  );
};
