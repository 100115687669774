import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (
          isAxiosError(error) &&
          error.response?.status &&
          error.response.status < 500
        ) {
          // don't retry 4xx errors (like 401 Unauthorized or 404 Not Found),
          // as they usually cannot be fixed by retrying
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});
