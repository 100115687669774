import { Flex, Spinner } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticationAuthenticateWithToken } from "../../shared/api";
import { ApiError } from "../../shared/components/api-error.component";
import { AuthenticationContext } from "../authentication.context";

export function AuthenticateWithToken({ token }: { token: string }) {
  const authenticationContext = useContext(AuthenticationContext);
  const authenticate = useAuthenticationAuthenticateWithToken();

  useEffect(() => {
    if (
      authenticate.isPending ||
      authenticate.isError ||
      authenticate.isSuccess
    ) {
      return;
    }
    authenticate.mutate({ body: { token } });
  }, [authenticate, token]);

  if (authenticate.isSuccess) {
    authenticationContext.setToken(token);
  }
  if (authenticate.isError) {
    authenticationContext.setToken(null);
  }

  return (
    <Flex
      direction="column"
      gap="32px"
      w="320px"
      maxW="100%"
      alignItems="center"
    >
      {authenticate.isPending && <Spinner />}
      {authenticate.isSuccess && <Navigate to="/admin" />}
      <ApiError result={authenticate} />
    </Flex>
  );
}
