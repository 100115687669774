import { useRecoilValue } from "recoil";
import { CampingQuote } from "use-smart-locks-shared";
import { useCampingQuoteQuery } from "../queries/use-camping-quote-query";
import { selectedDurationState } from "../state/selected-duration.state";
import { selectedPickupDateState } from "../state/selected-pickup-date.state";
import { useCampingStation } from "./use-camping-station";
import { useCampingStationSlug } from "./use-camping-station-slug";

export const useCampingQuote = (): CampingQuote => {
  const station = useCampingStation();
  const stationSlug = useCampingStationSlug();
  const selectedPickupDate = useRecoilValue(
    selectedPickupDateState(stationSlug),
  );
  const selectedDuration = useRecoilValue(selectedDurationState(stationSlug));

  if (!selectedPickupDate || !selectedDuration) {
    throw new Error(
      "selectedPickupDateState and selectedDurationState is required",
    );
  }

  const { data } = useCampingQuoteQuery(station.id, {
    pickupDate: selectedPickupDate,
    duration: selectedDuration,
  });

  return data;
};
