import { ArrayNotEmpty } from "class-validator";
import { Interface } from "../types";
import { CampingQuoteRequest } from "./camping-quote-request";

export class CampingCheckoutRequest extends CampingQuoteRequest {
  constructor(data?: Interface<CampingQuoteRequest>) {
    super(data);

    if (data) {
      Object.assign(this, data);
    }
  }

  @ArrayNotEmpty()
  unitIds: string[];
}
