import { useParams } from "react-router-dom";

export const useCampingStationSlug = (): string => {
  const { stationSlug } = useParams();

  if (!stationSlug) {
    throw new Error("stationSlug route parameter is required");
  }

  return stationSlug;
};
