import { Flex, Heading } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import useAppLogo from "../assets/use-app-icon.png";
import { AuthenticateWithToken } from "./components/authenticate-with-token.component";
import { LoginForm } from "./components/login-form.component";

export function LoginPage() {
  const [params] = useSearchParams();
  const token = params.get("token");

  return (
    <Flex gap="112px" direction="column" align="center">
      <Flex gap="32px" direction="column" align="center">
        <div>
          <img src={useAppLogo} className="shared-logo" alt="Use logo" />
        </div>
        <Heading as="h1">Login</Heading>
      </Flex>
      <Flex direction="column" gap="32px" w="320px" maxW="100%">
        {token ? <AuthenticateWithToken token={token} /> : <LoginForm />}
      </Flex>
    </Flex>
  );
}
