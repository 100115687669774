import { Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { IconLogout } from "../../shared/components/icons";

import { useContext } from "react";
import { AuthenticationContext } from "../authentication.context";

export function Profile() {
  const authenticationContext = useContext(AuthenticationContext);

  const logOut = () => {
    authenticationContext.setToken(null);
    window.location.reload();
  };

  return (
    <Flex direction="row" height="64px" justify="end" align="center">
      {authenticationContext.token ? (
        <Tooltip hasArrow label="Ausloggen">
          <IconButton
            isRound={true}
            variant="outline"
            aria-label="Account"
            icon={<IconLogout fill="currentColor" />}
            onClick={logOut}
          />
        </Tooltip>
      ) : null}
    </Flex>
  );
}
