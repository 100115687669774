import { Type } from "class-transformer";
import { IsDate, IsIn } from "class-validator";
import { Interface } from "../types";
import { BookingDuration, bookingDurations } from "./booking-duration";

export class CampingQuoteRequest {
  constructor(data?: Interface<CampingQuoteRequest>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  @IsDate()
  @Type(() => Date)
  pickupDate: Date;

  @IsIn(bookingDurations)
  duration: BookingDuration;
}
