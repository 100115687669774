import {
  Avatar,
  Blockquote,
  Box,
  Button,
  CheckboxCards,
  Flex,
  Text,
} from "@radix-ui/themes";
import { usePostHog } from "posthog-js/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import {
  bookingDurationLabels,
  formatCurrency,
  formatDate,
  formatTime,
} from "use-smart-locks-shared";
import { routes } from "../../routes";
import {
  getApiUserErrorMessage,
  getCheckoutUrl,
} from "../../shared/api/nest-api";
import { useCampingQuote } from "../hooks/use-camping-quote";
import { useCampingStationSlug } from "../hooks/use-camping-station-slug";
import { selectedUnitIdsState } from "../state/selected-unit-ids.state";

export const CampingRentBikeSelectionPage: React.FC = () => {
  const slug = useCampingStationSlug();
  const quote = useCampingQuote();
  const posthog = usePostHog();

  const [selectedUnitIds, setSelectedUnitIds] = useRecoilState(
    selectedUnitIdsState(slug),
  );

  useEffect(() => {
    posthog.capture("selected_unit_ids", { value: selectedUnitIds });
  }, [posthog, selectedUnitIds]);

  useLayoutEffect(() => {
    setSelectedUnitIds((current) =>
      current.filter((currentUnitId) =>
        quote.units.some((unit) => unit.id === currentUnitId && unit.available),
      ),
    );
  }, [quote, setSelectedUnitIds]);

  const navigate = useNavigate();
  const [navigating, setNavigating] = useState(false);

  const handleEditBookingPeriod = () => {
    navigate(routes.camping.rent.index(slug));
  };

  const handleNextClick = () => {
    setNavigating(true);

    getCheckoutUrl(quote.campingStationId, {
      duration: quote.duration,
      pickupDate: new Date(quote.pickupDate),
      unitIds: selectedUnitIds,
    })
      .then((checkoutResponse) => {
        window.location.href = checkoutResponse.checkoutUrl;
      })
      .catch((error: unknown) => {
        toast.error(
          getApiUserErrorMessage(error) ??
            "Leider ist ein unerwarteter Fehler aufgetreten",
        );
        setNavigating(false);
      });
  };

  const nextEnabled = selectedUnitIds.length > 0;

  return (
    <Flex direction="column" gap="4">
      <Text>
        Wählen Sie nun die gewünschten Räder für Ihren Buchungszeitraum aus.
      </Text>

      <Flex align="center">
        <Blockquote>
          {formatDate(quote.pickupDate)}, ab {formatTime(quote.pickupDate)} für{" "}
          {bookingDurationLabels[quote.duration]}
        </Blockquote>

        <Button
          size="1"
          variant="ghost"
          onClick={handleEditBookingPeriod}
          ml="3"
        >
          (ändern)
        </Button>
      </Flex>

      <Text>
        Die angezeigten Preise sind jeweils für den gesamten Buchungszeitraum.
      </Text>

      <CheckboxCards.Root
        columns="1"
        value={selectedUnitIds}
        onValueChange={setSelectedUnitIds}
      >
        {quote.units.map((unit) => (
          <CheckboxCards.Item
            key={unit.id}
            value={unit.id}
            disabled={!unit.available}
          >
            <Flex gap="3" align="center" width="100%">
              <Avatar
                className="unit"
                size="3"
                src={unit.imageUrl}
                radius="large"
                fallback={unit.name.charAt(0)}
                style={{ objectFit: "cover" }}
              />
              <Box flexGrow="1">
                <Text as="div" size="2" weight="bold">
                  {unit.name}
                </Text>
                <Text as="div" size="2" color="gray">
                  {unit.description}
                </Text>
              </Box>
              <Box width="80px">
                <Text as="div" align="right">
                  {unit.available
                    ? formatCurrency(unit.priceInCents / 100)
                    : "ausgebucht"}
                </Text>
              </Box>
            </Flex>
          </CheckboxCards.Item>
        ))}
      </CheckboxCards.Root>

      <Flex justify="end">
        <Button
          onClick={handleNextClick}
          disabled={!nextEnabled || navigating}
          loading={navigating}
        >
          weiter zur Bestellübersicht
        </Button>
      </Flex>
    </Flex>
  );
};
