import { Button, Flex, Link } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FileUpload } from "../shared/components/file-upload.component";
import { Info } from "../shared/components/info.component";

const formValidationSchema = Yup.object({
  csvFile: Yup.mixed().required("CSV-Datei ist erforderlich"),
});

function sanitizeEmail(email: string): string {
  return email.trim().toLowerCase();
}

function isValidEmail(email: string): boolean {
  return Yup.string().email().isValidSync(email);
}

function sanitizeAndValidateEmail(email: string): string | null {
  const sanitizedEmail = sanitizeEmail(email);
  return isValidEmail(sanitizedEmail) ? sanitizedEmail : null;
}

const exampleCsvFile =
  "data:text/csv;base64,ZW1haWwKbWFya3VzQHVzZS1hbGwuZGUKc2ltb25AYnJhaW5jaGltcHMuY29t";

export function EmailsCsvImportForm({
  importEmails,
  isLoading,
}: {
  importEmails: (emails: string[]) => void;
  isLoading: boolean;
}) {
  return (
    <Formik
      initialValues={{
        csvFile: null as File | null,
      }}
      onSubmit={(values) => {
        if (values.csvFile === null) {
          return;
        }

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const content = e.target?.result as string;

          const validEmails = content
            .split("\n")
            .map(sanitizeAndValidateEmail)
            .filter((email): email is string => !!email);

          importEmails(validEmails);
        };
        fileReader.onerror = (e) => {
          console.error("Error reading file: ", e);
        };
        fileReader.onabort = (e) => {
          console.error("File reading aborted: ", e);
        };
        fileReader.readAsText(values.csvFile);
      }}
      validationSchema={formValidationSchema}
    >
      {({ handleSubmit, errors, touched, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="32px">
            <FileUpload
              label="CSV-Datei"
              name="csvFile"
              error={touched.csvFile ? errors.csvFile : ""}
              onChange={(file) => void setFieldValue("csvFile", file)}
            />
            <Button type="submit" isLoading={isLoading}>
              Importieren
            </Button>
            <Info type="info">
              Überschreibt alle vorhandenen E-Mail-Adressen mit denen aus der
              CSV-Datei.
            </Info>
            <Link
              colorScheme="blue"
              color="blue.500"
              target="_blank"
              href={exampleCsvFile}
            >
              Beispiel-Datei herunterladen
            </Link>
          </Flex>
        </form>
      )}
    </Formik>
  );
}
