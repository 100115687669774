import { AtomEffect } from "recoil";

export const localStorageEffect =
  <T>(
    serializer: {
      serialize: (value: T) => string;
      deserialize: (json: string) => T;
    } = {
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    },
  ): AtomEffect<T> =>
  ({ setSelf, onSet, node }) => {
    const key = node.key;

    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(serializer.deserialize(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, serializer.serialize(newValue));
    });
  };
